import React from 'react';
import { Layout,Row,Col} from 'antd';
import "./layout.css"
import './../assets/css/index.css';
import './../assets/index.scss';
import {LogoutOutlined} from '@ant-design/icons';
import { Logout } from "./../actions/Auth"
import { connect } from "react-redux"
import { navigate } from "gatsby";
const { Content,Header } = Layout;
const DriverLayout =(props)=>{
    const {children,Logout}=props
    const signOut=()=>{
        navigate('/auth')
          localStorage.removeItem('token')
          localStorage.removeItem('role_id')
          localStorage.removeItem('data')
          localStorage.removeItem('userName')
          localStorage.removeItem('yard')
          Logout()
      }
    return(
        <Layout className="app-layout" style={{alignItems:'center'}}>
              <Header className="app-header" >
              <div  style={{width:'100%',display:'flex'}} >
                <div style={{fontSize:25,width:'90%',textAlign:'start',marginLeft:25}}>
                    <div >EQUIPMENT DROP-OFF</div>
                    </div>
            <div style={{fontSize:25,width:'10%',textAlign:'end',paddingRight:20,color:'#ff6b72'}} onClick={()=>signOut()}><LogoutOutlined/></div>
           </div>
              </Header>
        <Content className={`app-content`} style={{maxWidth:1000}}>
            {children}
        </Content>
        <style>{`
        
        
        `}</style>
        </Layout>
    )
}

export default connect(null, { Logout })(DriverLayout)