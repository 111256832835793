export const Equipment =() => [
    {
      name: 'client_cust_id',
      col: 8,
      lable: 'CUSTOMER',
      type: 'dropdown',
      value: [],
      rules: [
        {
          required: true,
          message: 'Please Select Customer!',
          whitespace: true,
        },
      ],
    },
      {
        name: 'driver_name',
        lable: 'DRIVER',
        col: 8,
      },
      {
        name: 'trucking_carrier',
        lable: 'TRUCK CARRIER',
        col: 8,
      },
      {
        name: 'equipment_no',
        lable: 'EQUIPMENT NUMBER',
        col: 8,
        type: 'autocomplete',
        value: [],
        rules: [
          {
            required: true,
            message: 'Please Enter equipment number!',
            whitespace: true,
          },
        ],
      },
    {
      name:'custom1'
    },
    // {
    //   name: 'equipment_type',
    //   col: 6,
    //   lable: '',
    //   type: 'dropdown',
    //   value: [],
    //   rules: [
    //     {
    //       required: false,
    //       message: 'Please Select equipment type!',
    //       whitespace: true,
    //     },
    //   ],
    // },
    
    {
      name: 'quantity',
      lable: 'QUANTITY',
      type:'number',
      col: 4,
    },
    {
        name: 'generator',
        lable: 'FROM/GENERATOR',
        col: 6,
        rules: [
          {
            required: false,
            message: 'Please Enter form generator!',
            whitespace: true,
          },
        ],
      },
      {
        name: 'last_known',
        lable: 'HAZARDOUS',
        type: 'radio',
        col: 6,
        value: [
          { value: 'nonhaz', displayValue: 'No' },
          { value: 'haz', displayValue: 'Yes' },
        ],
        rules: [],
      },
      {
        name: 'hazardous',
        lable: 'LAST CONTAINED',
        disabled: false,
        col: 6,
        rules: [
          {
            message: 'Please Enter Last Contained!',
            whitespace: true,
          },
        ],
        
      },
      {
        name: 'job_site',
        lable: 'JOBSITE',
        col: 6,
      },
    // {
    //   name: 'owner',
    //   lable: 'OWNER',
    //   col: 8,
    // },
    {
        name:'custom2'
      },
    // {
    //   name: 'remark',
    //   type: 'textArea',
    //   lable: 'DETAILS FOR BULK ITEMS/ REMARKS:',
    //   col: 24,
    //   rules: [],
    // },
    // {
    //   name: 'generator',
    //   lable: 'FROM/GENERATOR',
    //   col: 12,
    //   rules: [
    //     {
    //       required: true,
    //       message: 'Please Enter form generator!',
    //       whitespace: true,
    //     },
    //   ],
    // },
    // {
    //     name: 'manifest',
    //     lable: 'MANIFEST#',
    //     col: 12,
    //     rules: [
    //       {
    //         message: 'Please Enter manifest!',
    //         whitespace: true,
    //       },
    //     ],
    //   },


    
    {
      name: 'location_id',
      col: 12,
      lable: 'YARD NAME',
      type: 'dropdown',
      value: [],
      rules: [
        {
          required: true,
          message: 'Please Select yard name!',
          whitespace: true,
        },
      ],
    },

    
  ];
